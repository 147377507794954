
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        











































































































































































































































































































































// Flickity
.smartphone {
  .flickity-viewport {
    position: relative;
    padding-left: $spacing * 4;
    cursor: grab;

    @include mq('m') {
      padding-left: $spacing * 6;
    }

    @include mq(xl) {
      padding-left: $spacing * 9;
    }
  }

  .flickity-slider {
    width: col(12);
    height: 100%;
  }

  .flickity-button {
    @extend %button-nostyle;
    @include center-y;

    top: inherit;
    bottom: 0;
    left: $spacing;
    width: 4rem;
    height: 4rem;
    margin-top: -$spacing;
    border: 2px solid $c-anthracite-light;
    border-radius: 100%;
    transition: 0.3s $ease-out-quad;
    transition-property: background-color, opacity;

    &:disabled {
      opacity: 0.3;
    }

    &.previous {
      margin-top: $spacing * 2.5;
    }

    &.next {
      margin-bottom: $spacing * 2.5;
    }

    @include mq('m') {
      left: $spacing * 3.5;
    }

    @include mq('l') {
      left: $spacing * 2;
    }

    @include mq('xl') {
      left: $spacing * 4.25;
    }

    @include mq(l) {
      &:hover:not(:disabled) {
        background-color: $c-anthracite-light;

        /* stylelint-disable-next-line max-nesting-depth */
        .flickity-button-icon {
          fill: $white;
        }
      }
    }
  }

  .flickity-button-icon {
    @include center-xy;

    width: 2rem;
    height: 2rem;
    fill: $c-anthracite-light;
    transition: fill 0.3s $ease-out-quad;
  }
}
