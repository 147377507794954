
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































































































































































































































.mobile-card {
  ::v-deep {
    .product-card__main {
      padding: 0 2.5rem 0;
    }

    .selection__header__logo {
      display: none;
    }

    .btn.cta__order {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .price__original,
    .price__stack__months {
      color: $c-blue-medium;
    }

    .price-htva {
      text-align: center;
    }
  }
}

.mobile-card__selection {
  margin-bottom: 2rem;
}

.mobile-card__data {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 4.8rem;
  line-height: 1;
  text-align: center;

  small {
    font-size: 1.8rem;
  }
}

.mobile-card__btns {
  display: flex;
  align-content: center;
  margin: 2rem 0 4rem;

  .no-advantage & {
    margin: 2rem 0;
  }
}

.mobile-card__btn {
  flex-basis: 50% !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 28rem;
  margin: 0 auto;
  padding: 1rem;
  line-height: 1;
  flex-shrink: 0;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  &.is-selected {
    background-color: $white;
    border: 2px solid $c-blue-medium;

    &::before {
      content: '';
      display: none !important;
    }

    * {
      color: $c-blue-medium !important;
    }
  }

  ::v-deep {
    .choice-btn__check {
      display: none;
    }

    .choice-btn__figure {
      display: none;
    }

    .choice-btn__label {
      font-weight: 900;
      color: $c-blue-medium !important;
    }

    .mobile-card__btn__price {
      margin-inline: 0;
    }
  }
}

.mobile-card__btn__price,
::v-deep .choice-btn__label {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.3rem;
  text-transform: none;
}

.mobile-card__btn__price {
  @extend %fw-bold;

  position: relative;
  z-index: 5;
  margin-top: $spacing / 4;
  color: $c-anthracite-light;
}

.mobile-card__content {
  @extend %fw-normal;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}

.mobile-card__content__text {
  flex: 1;
  color: $c-anthracite-light;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: center;

  ::v-deep p {
    margin: 0;
  }
}

.mobile-card__content__icon,
.mobile-card__content__tooltip {
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }
}

.mobile-card__cta,
::v-deep .btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 1rem 2rem;
  line-height: 1;
}

.advantage {
  @include center-x;

  position: absolute;
  top: calc(100% - 3.5rem);

  .price-htva + & {
    top: calc(100% - 6rem);

    @include mq(l) {
      top: calc(100% - 6.5rem);
    }
  }
}
