
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































































::v-deep .banner-speedlines {
  margin-bottom: $spacing * 2;

  .banner-speedlines__icon::before {
    background: $c-green-light;
  }
}

.hub-mobile__options {
  padding-bottom: 0 !important;

  ::v-deep {
    @include mq(l) {
      .carousel__item {
        width: calc(33% - 50px);
      }
      .option-card {
        width: 100%;
      }
    }
  }

  @include mq($until: l) {
    margin-bottom: 5rem;
  }
}

.hub-mobile__options__title {
  margin-bottom: 2rem;
  text-align: center;

  @include mq(m) {
    margin-bottom: 5rem;
  }
}

.hub-mobile__duoListCard {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
