
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































































.tooltip,
[class*='tooltip--'] {
  position: relative;
}

.tooltip__figure {
  margin: 0;
  // padding: $spacing * .5;

  .clickable & {
    cursor: pointer;
  }
}

.tooltip__icon {
  width: 1.3rem;
  height: 1.3rem;

  @include mq(m) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

#tooltip,
.tooltip__content {
  @extend %box-shadow;

  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 25rem;
  height: auto;
  border: 0.1rem solid $c-blue-lightest;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-70%);
  transition: opacity 0.3s $ease;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 70%;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.1rem solid $c-blue-lightest;
    background: $white;
    transform: translate(-50%, 50%) rotate(45deg);
    border-radius: 3px;
  }

  @include mq(m) {
    max-width: 30rem;

    &.large {
      max-width: 40rem;
    }
  }
}

.tooltip__content {
  display: none;
}

.tooltip__content__inner {
  position: relative;
  z-index: 10;
  padding: $spacing;
  background: $white;
  border-radius: 8px;

  .icon {
    position: absolute;
    z-index: 20;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
  }

  @include mq(l) {
    .icon {
      display: none;
    }
  }
}
