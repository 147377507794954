
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















































































.choice-btn,
[class*='choice-btn--'] {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacing * 1.5;
  background: $white;
  border: 0.1rem solid $c-gray-lightest;
  border-radius: 5px;

  .tooltip {
    display: none;
  }

  &.is-static {
    pointer-events: none;
  }

  &.is-selected {
    &::before {
      opacity: 1;
      transform: scale(1.04);
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $c-anthracite-light;
    border-radius: 5px;
    opacity: 0;
    transition: transform 0.3s $ease-out-back, opacity 0.1s $ease;
  }

  // &:not(:last-child) {
  //   margin-bottom: $spacing * 0.75;
  // }

  @include mq(l) {
    flex-basis: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
    padding: $spacing * 2;

    // &:not(:last-child) {
    //   margin-bottom: $spacing;
    // }

    .tooltip {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    .tooltip__content {
      position: absolute;
      top: auto;
      bottom: 105%;
      left: 50%;
      display: block;
      min-width: 30rem;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 0);
    }
  }

  /* stylelint-disable-next-line */
  &:hover {
    .tooltip__content {
      visibility: visible;
      opacity: 1;
    }
  }
}

.choice-btn__check,
.choice-btn__label,
.choice-btn__figure {
  position: relative;
  z-index: 5;
}

.choice-btn__figure {
  margin-right: $spacing * 0.5;
  line-height: 1;

  img {
    max-width: 10rem;
    max-height: 3rem;
  }

  .icon {
    width: 5.5rem;
    height: 3rem;
    fill: $c-blue-dark;

    .choice-btn.is-selected & {
      fill: $white;
    }
  }

  @include mq(m) {
    .icon {
      height: 4rem;
    }

    img {
      max-height: 6rem;
    }
  }

  /* stylelint-disable-next-line */
  & + & {
    margin-left: $spacing / 2;

    &::before {
      @include center-y;

      content: '+';
      left: -$spacing / 2;
      display: block;
      transform: translateY(-90%);

      .is-selected & {
        color: $white;
      }
    }

    @include mq(xxl) {
      margin-left: $spacing;

      &::before {
        left: -$spacing;
      }
    }
  }
}

.choice-btn__label {
  @extend %fw-bold;

  display: block;
  margin-right: 0.5rem;
  color: $c-anthracite-light;
  font-family: $ff-alt;
  font-size: 1.5rem;
  text-transform: uppercase;

  .choice-btn.is-selected & {
    color: $white;
  }
}

.choice-btn__check {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  background-color: $c-gray-lightest;
  border-radius: 50%;
  transition: background-color 0.1s $ease;

  svg {
    @include center-xy;

    width: 1.8rem;
    height: 1.5rem;
    opacity: 0;
    fill: $white;
    transition: opacity 0.1s $ease;
  }

  .overlayer & {
    order: -1;
    margin-right: $spacing / 2;
  }

  .choice-btn.is-selected & {
    background-color: $c-anthracite-lighter;

    svg {
      opacity: 1;
    }
  }

  .choice-btn.is-static & {
    display: none;
  }
}
