
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

































































$bp: m;

.content-pill {
  @include bg-color;

  overflow: hidden;
  color: $white;
  background-color: $c-blue-dark;
}

.content-pill-inner {
  position: relative;
}

.content-pill__icon {
  @include fluid-type(15, 18);

  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .icon {
    max-height: 4rem;
    margin-right: 1rem;
    fill: $white;
  }

  @include mq($bp) {
    margin-bottom: 3rem;
  }
}

.content-pill__content {
  position: relative;
  z-index: 1;

  @include mq(l) {
    max-width: 40rem;
  }
  @include mq(xl) {
    max-width: 58rem;
  }
  @include mq(xxl) {
    max-width: 60rem;
  }
}

.content-pill__title {
  @include fluid-type(24, 36);

  margin: 0 0 2rem;
}

.content-pill__text {
  @extend %fw-light;
  @include fluid-type(15, 18);

  margin-bottom: 2rem;
  line-height: 1.6;

  @include mq($bp) {
    margin-bottom: 3rem;
  }
}

.content-pill__speedlines {
  top: 6rem;
  right: 5rem;
  height: 130%;

  @include mq(xl) {
    right: 10rem;
  }

  @include mq($until: l) {
    ::v-deep .speedlines__bg {
      display: none;
    }
  }
}
